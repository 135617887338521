<script lang="ts" setup>
// Composables
const route = useRoute();

// Store
const { currentSite } = storeToRefs(adminStore());

// Composables
const { getSite } = useSiteApi();
const { t } = useI18n();

// Data
const loading = ref(false);
const title = computed(() => `${t('global.site')}: ${currentSite.value?.name ?? ''}`);

const breadcrumb = computed(() => [
  {
    name: t('global.admin'),
    url: '/admin',
  },
  {
    name: t('global.clients'),
    url: '/admin/clients',
  },
  {
    name: `${currentSite.value?.client.name ?? ''}`,
    url: `/admin/clients/${route.params.id}`,
  },
]);

const tabs = computed(() => [
  {
    label: t('global.informations'),
    to: `/admin/site/${route.params.id}`,
  },
  {
    label: t('global.areas'),
    to: `/admin/site/${route.params.id}/areas`,
  },
]);

onMounted(async () => {
  loading.value = true;
  await getSite(parseInt(route.params.id as string));
  loading.value = false;
});
</script>
<template>
  <ui-page :title="title" :breadcrumb="breadcrumb" :tabs="tabs" :loading="loading" :error="!currentSite" />
  {{ $route.params.id }}
</template>
